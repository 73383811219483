import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { service } from "@ember/service";
import PluginOutlet from "discourse/components/plugin-outlet";
import avatar from "discourse/helpers/bound-avatar-template";
import { smallUserAttrs } from "discourse/lib/user-list-attrs";
import i18n from "discourse-common/helpers/i18n";
import getURL from "discourse-common/lib/get-url";
export default class SmallUserList extends Component {
    @service
    currentUser;
    get users() {
        let users1 = this.args.data.users;
        if (this.args.data.addSelf && !users1.some((u1)=>u1.username === this.currentUser.username)) {
            users1 = users1.concat(smallUserAttrs(this.currentUser));
        }
        return users1;
    }
    get postUrl() {
        const url1 = this.users.find((user1)=>user1.post_url);
        if (url1) {
            return getURL(url1);
        }
    }
    static{
        template(`
    <PluginOutlet
      @name="small-user-list-internal"
      @outletArgs={{hash data=this.args}}
    >
      {{#each this.users as |user|}}
        {{#if user.unknown}}
          {{! template-lint-disable require-context-role }}
          <div
            title={{i18n "post.unknown_user"}}
            class="unknown"
            role="listitem"
          ></div>
        {{else}}
          {{! template-lint-disable require-context-role }}
          <a
            class="trigger-user-card"
            data-user-card={{user.username}}
            title={{user.username}}
            aria-hidden="false"
            role="listitem"
          >
            {{avatar user.template "tiny"}}
          </a>
        {{/if}}
      {{/each}}

      {{#if @data.description}}
        {{#if this.postUrl}}
          <a href={{this.postUrl}}>
            <span aria-hidden="true" class="list-description">
              {{i18n @data.description count=@data.count}}
            </span>
          </a>
        {{else}}
          <span aria-hidden="true" class="list-description">
            {{i18n @data.description count=@data.count}}
          </span>
        {{/if}}
      {{/if}}
    </PluginOutlet>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
